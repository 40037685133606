"use client";

import { useSession } from "next-auth/react";
import { PropsWithChildren, useEffect } from "react";
import { Intercom, LiveChatLoaderProvider } from "react-live-chat-loader";

export function IntercomProvider({ children }: PropsWithChildren) {
  const { data: session } = useSession();

  const projectId = process.env.NEXT_PUBLIC_INTERCOM_PROJECT_ID ?? "";

  useEffect(() => {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: projectId,
      user_id: session?.user.id,
      email: session?.user?.email ?? undefined,
      user_hash: session?.user?.intercomHash,
      bought_frame_pack: !!session?.user?.hasBoughtFramePacks,
      subscription_tier: session?.user?.subscriptionTier,
      username: session?.user?.username,
    };
  }, [session?.user]);

  return (
    <LiveChatLoaderProvider providerKey={projectId} provider="intercom" idlePeriod={3000}>
      {children}
      <Intercom color="#3C1DFF" />
    </LiveChatLoaderProvider>
  );
}
